import React from 'react'
import Layout from '../../components/layout';

import { longforms } from "../../data/longforms";
import Template from "./../../templates/longform";

const CharoPage = () => {
  console.log(longforms);
  return(
  <Layout>
      <Template
        title={longforms[1].post.title}
        coverUrl={longforms[1].post.coverUrl}
        type={longforms[1].post.type}
        timeline={longforms[1].post.timeline}
        challenge={longforms[1].post.challenge}
        solution={longforms[1].post.solution}
        awards={longforms[1].post.awards}
        links={longforms[1].post.links}
        body={longforms[1].post.body}
      />
  </Layout>
  )
}

export default CharoPage
